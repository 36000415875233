<template lang="pug">
.data-migration.card.animated.fadeIn
  .card-header {{ $t('menu:migration') }}
  .card-body
    form
      fi-form-field
        .form-row
          .col: fi-file-input(v-model = 'migrationData')
          .col
            .btn-group
              button.btn.btn-primary(
                :disabled = 'migrationLoader || !migrationData'
                @click.prevent = 'sendData'
              )
                | {{ $t('uploadData') }}
                i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'migrationLoader')
              button.btn.btn-primary(
                :disabled = 'migrationLoader'
                @click.prevent = 'documentsMigration'
              )
                | {{ $t('documentMigration') }}
                i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'migrationLoader')
              button.btn.btn-light(
                @click.prevent = 'loadMigrationStatus'
                :disabled = 'loader'
              )
                | {{ $t('getStatus') }}
                i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    .card.animated.fadeIn(v-if = 'status')
      .card-header {{ $t('status') }}
      template(v-for = '(stage, stageKey) in status.stages')
        .card-header.bg-success(v-b-toggle = 'stageKey'
                                :key       = 'stageKey'
                                :class     = '{ "bg-danger": stage.length }') {{ stageKey | ucFirst }}
        b-collapse.animated.fadeIn(v-if = 'stage.length'
                                   :id  = 'stageKey'
                                   visible)
          .card-block
            .table-responsive
              table.table.table-hover
                thead: tr
                  th {{ $t('module') }}
                  th {{ $t('error') }}
                  th {{ $t('description') }}
                tbody: tr(v-for = '(error, key) in stage'
                          :key  = 'key')
                  td {{ error.module }}
                  td {{ error.message }}
                  td {{ error.description }}

</template>

<script>
import FiFileInput from '@/components/FiFileInput'
import FiFormField from '@/components/FiFormField'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'data-migration',

  components: { FiFormField, FiFileInput },

  i18nOptions: {},

  data: () => ({
    migrationData: null
  }),

  computed: {
    ...mapState('migration', ['status']),
    loader () {
      return this.$vueLoading.isLoading('migration:status:fetch')
    },
    migrationLoader () {
      return this.$vueLoading.isLoading('migration:process')
    }
  },

  methods: {
    ...mapActions('migration', ['uploadDataFile', 'loadMigrationStatus', 'startDocumentsMigration']),
    sendData () {
      this.uploadDataFile({ file: this.migrationData })
    },
    async documentsMigration () {
      await this.startDocumentsMigration()
    }
  }

}
</script>


<style lang='scss' scoped>
  .card-header {
    cursor: pointer;
  }
</style>


<i18n>
en:
  uploadData:        "Start Data migration"
  documentMigration: "Start Documents migration"
  status:            "Migration status"
  module:            "Module"
  error:             "Error message"
  description:       "Description"
  getStatus:         "Refresh migration status"
et:
  uploadData:        "Start Data migration"
  documentMigration: "Start Documents migration"
  status:            "Migration status"
  module:            "Module"
  error:             "Error message"
  description:       "Description"
  getStatus:         "Refresh migration status"
ru:
    uploadData:  "Start Data migration"
    status:      "Data migration status"
    module:      "Module"
    error:       "Error message"
    description: "Description"
    getStatus:   "Refresh migration status"
</i18n>
